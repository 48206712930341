import request from "@/utils/request";


//用户登录
export function testApi() {
    return request({
        url: '/v1/open/test?key=123',
        method: 'get',
    })
}
