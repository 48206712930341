<template>
  <header>
    <div class="l-content">
      <el-button plain icon="el-icon-menu" size="mini" @click="handleMenu"></el-button>
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="current.path" v-if="current && current.meta && current.meta.title">
          {{ current.meta.title }}
        </el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="r-content">

      <!--网络测试-->
      <el-button type="primary" size="mini" @click="handleTest">网络测试</el-button>
      <!--主题设置-->
      <div v-show="isDarkTheme" class="theme-toggle ml-4" @click="handleThemeChange">
        <i class="fas fa-moon"></i>
      </div>
      <div v-show="!isDarkTheme" class="theme-toggle" @click="handleThemeChange">
        <i class="fas fa-sun"></i>
      </div>
      <el-dropdown trigger="click" size="mini" @command="handleCommand">
        <span class="el-dropdown-link">
          <img :src="userImage" alt="" class="user">
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item>个人中心</el-dropdown-item>
          <el-dropdown-item command="logout">退出</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <!-- 网络测试结果弹框 -->
    <el-dialog
        title="网络测试结果"
        :visible.sync="showTestDialog"
        width="50%"
        @close="handleDialogClose"
    >
      <p>{{ url }}</p>
      <div v-if="loading">
        <el-spin tip="正在加载..."></el-spin>
      </div>
      <div v-else>
        <pre>{{ testResult }}</pre>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTestDialog = false">关闭</el-button>
      </span>
    </el-dialog>
  </header>

</template>

<script>
import {mapState, mapActions} from 'vuex';
import {testApi} from "@/api/test";

export default {
  name: "CommonHeader",
  data() {
    return {
      userImage: require('../assets/image/user.png'),
      isDarkTheme: true, // 默认主题状态
      testResult: '', // 存储测试结果
      url: '', // 存储测试结果
      showTestDialog: false, // 控制弹框显示
      loading: false, // 控制加载状态

    };
  },

  methods: {
    handleDialogClose() {
      // 可选：在弹框关闭时清理测试结果
      this.testResult = '';
    },
    handleMenu() {
      this.$store.commit("tab/collapseMenu");
    },
    handleTest() {
      //  https://t-api.anguomob.com/api/v1/open/test?key=123
      this.loading = true;
      testApi().then(res => {
        this.loading = false;
        this.testResult = JSON.stringify(res.data, null, 2);
        this.url = `${process.env.VUE_APP_HOST}/api/v1/open/test?key=123`;
        this.showTestDialog = true;
      })
          .catch(() => {
            this.loading = false;
          });
    },
    handleCommand(command) {

      switch (command) {
        case "logout":
          this.$store.commit('user/logout')
          this.$router.push(`/login?redirect=${this.$route.fullPath}`)
          break
      }
    },
    handleThemeChange() {
      this.$store.dispatch('theme/toggleTheme');
      this.isDarkTheme = !this.isDarkTheme; // 切换 isDarkTheme 的值


    },
    ...mapActions('theme', ['toggleTheme'])
  }
  ,
  computed: {
    ...
        mapState({current: state => state.tab.currentMenu})


  },
  mounted() {
    // 设置默认主题为跟随系统
    const isDarkTheme = window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.$store.commit('theme/SET_THEME', isDarkTheme);
    this.isDarkTheme = isDarkTheme; // 初始化时同步 isDarkTheme 的值

  }
}
</script>

<style scoped lang="scss">
header {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.l-content {
  display: flex;
  align-items: center;

  .el-button {
    margin-right: 20px;
  }
}

.r-content {
  display: flex;
  align-items: center;

  .theme-toggle {
    cursor: pointer;
    margin-right: 20px;
    font-size: 1.5em;
    transition: color 0.3s;
  }

  .theme-toggle .fa-sun {
    color: #FFA500; /* Sun color */
  }

  .theme-toggle .fa-moon {
    color: #4B0082; /* Moon color */
  }

  .user {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}
</style>

<style lang="scss">
body.light {
  background-color: #ffffff;
  color: #000000;
}

body.dark {
  background-color: #333333;
  color: #ffffff;
}

.el-breadcrumb__item {
  .el-breadcrumb__inner {
    color: #666;
    font-weight: normal;
    transition: color 0.3s;
  }

  &:last-child {
    .el-breadcrumb__inner {
      color: #fff;
      font-weight: bold;
    }
  }
}

body.dark .el-breadcrumb__inner {
  color: #c0c4cc;
}

body.dark .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #fff;
}

body.light .el-breadcrumb__inner {
  color: #666;
}

body.light .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  color: #303133;
}
</style>

